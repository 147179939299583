(function (App) {

  var settings = {
    animDuration: 0.7
  };

  App.init = function() {
    // console.log('App.init()');

    initFastClick();

    initSlideshows();

    initNav();

    initProjectDrawers();

    // Call a public method like this
    // this.somePublicFunction();

    // Call a private method like this
    // privateFunction();
  };

  var initFastClick = function() {
    if ('addEventListener' in document) {
      document.addEventListener('DOMContentLoaded', function() {
        FastClick.attach(document.body);
      }, false);
    }
  };

  var initSlideshows = function() {
    var $fh = $('.flickity-hero');
    var flick;
    var $gallery;

    $gallery = $fh.flickity({
      cellAlign: 'left',
      cellSelector: '.slide',
      imagesLoaded: true,
      prevNextButtons: false,
      setGallerySize: false,
      wrapAround: true
    });

    function onLoadedData(event) {
      var cell = $gallery.flickity('getParentCell', event.target);
      $gallery.flickity('cellSizeChange', cell && cell.element);
    }

    $gallery.find('video').each(function(i, video) {
      if ($(this).hasClass('is-selected')) {
        video.play();
      }
      $(video).on('loadeddata', onLoadedData);
    });


    $('.hero-wrapper .btn-next, .hero-wrapper .hero-x').click(function(e) {
      // console.log('btn click: leaving slide', flick.selectedIndex);
      $fh.flickity('next', true);
    });

    flick = $fh.data('flickity');
    $fh.on('select.flickity', function() {
      // console.log('select', flick.selectedIndex);
      // toggleHeroCaption($fh, flick, false);
      toggleHeroX($fh, flick, false);
    });
    $fh.on('settle.flickity', function() {
      toggleHeroCaption($fh, flick, true);
      toggleHeroX($fh, flick, true);

      // Pause & reset all videos
      $gallery.find('video').each(function(i, video) {
        video.pause();
        video.currentTime = 0;
      });

      // If selected slide has a video, play it
      var $vids = $fh.find('.is-selected video');
      if ($vids.length > 0) {
        $vids[0].play();
        $vids[0].muted = true;
      }
    });
    $fh.on('dragStart.flickity', function() {
      // console.log('dragStart: leaving slide', flick.selectedIndex);
      toggleHeroCaption($fh, flick, false);
      toggleHeroX($fh, flick, false);
    });
    if (flick) {
      setTimeout(function() {
        toggleHeroCaption($fh, flick, true);
      }, 1250);
      toggleHeroX($fh, flick, true);
    }



    if ($('.flickity-staff-images').length > 0) {

      $('.flickity-staff-images').flickity({
        cellSelector: '.slide',
        imagesLoaded: true,
        prevNextButtons: false,
        sync: '.flickity-staff-text',
        wrapAround: true
      });
      $('.flickity-staff-text').flickity({
        cellSelector: '.slide',
        pageDots: false,
        prevNextButtons: false,
        wrapAround: true
      });
      $('.staff .btn-next').click(function(e) {
        $('.flickity-staff-images').flickity('next', true);
      });

      var flickStaff = $('.flickity-staff-images').data('flickity');
      // Workaround for flickity slider sometimes not sizing properly
      $(window).on('load', function() {
        flickStaff.resize();
      });

    }



  };

  var toggleHeroCaption = function($fh, flick, val) {
    var $captions = $fh.find('.caption');
    $captions.removeClass('show');
    if (val) {
      $captions.eq(flick.selectedIndex).addClass('show');
    }
  }

  var toggleHeroX = function($fh, flick, val) {
    var $heroX = $fh.find('.hero-x');
    if (val) {
      $heroX.addClass('show');
    } else {
      $heroX.removeClass('show');
    }
  }

  var initNav = function() {
    var $toggle = $('#mobile-nav-toggle');
    $toggle.click(function(event) {
      $toggle.toggleClass('is-active');
      $('.main-nav').toggleClass('open');
    });

    // Init sticky header
    $('header').sticky({zIndex: 99});
  };

  var initProjectDrawers = function() {
    if ($('.projects-container').length === 0) {
      return;
    }

    $('.more-button').click(function(e) {
      e.preventDefault();
      var initialButtonHeight = $(this).css('height');
      var initialButtonPadding = $(this).css('padding-top');
      var section = $(this).attr('id').replace('more-', '');
      var $container = $('.projects-container.container-' + section).addClass('expanded');
      var url = '/projects.php?section=' + section;
      var $btn = $(this);
      var $closeBtn = $container.find('.less-button');

      if (!$container.data('has-loaded')) {
        // Load project data
        $.get('/projects.php?section=' + section, function(data) {
            insertThumbs(section, data);
          }, 'html');

      } else {
        // Project data already loaded, just display it
        toggleShowAll(e.target, true);
        insertThumbs(section, null);
      }

      TweenLite.to($btn, settings.animDuration/2, {css:{height: 0, padding: 0}, ease:Cubic.easeInOut, onStart:toggleShowAll, onStartParams:[e.target, true]});
      TweenLite.set($closeBtn, {css:{display:'block', height:initialButtonHeight, paddingTop:initialButtonPadding}});
      TweenLite.from($closeBtn, settings.animDuration/2, {css:{height: 0, padding: 0}, ease:Cubic.easeInOut});

      // Wire up the close button
      if (!$closeBtn.data('wired')) {
        $closeBtn.data('wired', true).click(function(e) {
          toggleShowAll(e.target, false);
          TweenLite.to($closeBtn, settings.animDuration/2, {css:{height: 0, padding: 0}, ease:Cubic.easeInOut});
          TweenLite.set($btn, {css:{height:initialButtonHeight, paddingTop: initialButtonPadding}});
          TweenLite.from($btn, settings.animDuration/2, {css:{height: 0, padding: 0}, ease:Cubic.easeInOut});
        });
      }

    });


  };


  var toggleShowAll = function(targ, val) {
    var $parent = $(targ).parent();
    if (val) {
      // Unhide all elements when expanded
      $parent.find('ul li:nth-child(4)').css('display', 'block');
      $parent.find('ul li').slice(4).show();
    } else {
      // Hide certain elements when collapsed
      $parent.find('ul li:nth-child(4)').css('display', '');
      $parent.find('ul li').slice(4).hide();
    }
  };

  var insertThumbs = function(section, data) {
    var $section = $('.projects-container.container-' + section);
    var $ul = $section.find('ul');
    var $parsed;
    var existing = [];
    var added = false;
    var currentHeight = $ul.outerHeight();
    var newHeight = 0;


    if (!$section.data('has-loaded')) {
      $section.data('has-loaded', true);

      $parsed = $('<div/>').append(data);

      $ul.find('li').each(function() {
        existing.push($(this).attr('data-id'));
      });

      $parsed.find('li').each(function(index) {
        var $t = $(this);
        var id = $t.attr('data-id');
        if (existing.indexOf(id) === -1) {
          $ul.append($t);
          added = true;
        }
      });

      // Call pictureFill manually to work around IE11 srcset bug
      picturefill();

    } else {

      added = true;
    }


    if (added) {
      TweenLite.set($ul, {height:'auto', overflow:'hidden'});
      newHeight = $ul.outerHeight();

      TweenLite.from($ul, settings.animDuration, {css:{height: currentHeight}, ease: Cubic.easeInOut});
      // TweenLite.to()
      // TweenLite.from($links, settings.subNavTransitionDuration, {css:{height: 0}, ease:Cubic.easeInOut});
    }

  };

  // App.somePublicFunction = function() {
  //   console.log('somePublicFunction()');
  // };

  // var privateFunction = function() {
  //   console.log('privateFunction()');
  // };

  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds. If `immediate` is passed, trigger the function on the
  // leading edge, instead of the trailing.
  // via https://davidwalsh.name/javascript-debounce-function
  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

}(window.App = window.App || {}));

App.init();
